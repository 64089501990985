import { Navigate, Outlet } from "react-router-dom";

// Function to check token expiration
const isTokenExpired = (token) => {
  if (!token) return true;
  
  // Decoding the JWT to extract the expiration time
  const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decoding the token
  const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
  const currentTime = Date.now(); // Get current time in milliseconds
  
  return currentTime > expirationTime; // Returns true if expired
};

const useAuth = () => {
  const auth = sessionStorage.getItem("tokenotp");

  // Check if token exists and is not expired
  if (auth && !isTokenExpired(auth)) {
    return true;  // Authenticated
  } else {
    sessionStorage.removeItem("tokenotp");  // Clear expired token
    return false;  // Not authenticated
  }
};

const ProtectedRoutes = () => {
  const auth = useAuth();
  
  // If authenticated, render the child routes (`<Outlet />`); otherwise, navigate to login page
  return auth ? <Outlet /> : <Navigate to='/'/>;
};

export default ProtectedRoutes;