import { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { PATHS } from "../utils/constants";
import ProtectedRoutes from "./ProtectedRoute";

const MenuBar = lazy(() => import("../Pages/NavMenu/NavMenu"));
const LoginPage = lazy(() => import("../Pages/LoginPage/Login"));
const Dash = lazy(() => import("../Pages/Dashboard/Index"));
const Users = lazy(() => import("../components/Users/Users"));
const UserEdit = lazy(() => import("../components/Users/UserEdit"));
const IdentityRecords = lazy(() => import("../components/IdentityRecords/IdentityRecords"));
const Kyc = lazy(() => import("../components/IdentityRecords/Kyc"));
const Transactions = lazy(() => import("../components/IdentityRecords/Transactions"));
const IdentityRecordsView = lazy(() => import("../components/IdentityRecords/IdentityRecordsView"));
const Roles = lazy(() => import("../components/Roles/index"));
const Auditdashboard = lazy(() => import("../Pages/Dashboard/AuditDashboard"));
const Audit = lazy(() => import("../components/Audit"));
const Management = lazy(() => import("../components/Management/Index"));
const ManagementView = lazy(() => import("../components/Management/View"));
const ManagementPending = lazy(() => import("../components/Management/Pending"));
const ManagementCompleted = lazy(() => import("../components/Management/Completed"));
const Cancel = lazy(() => import("../components/Management/Cancel"));
const RoleView = lazy(() => import("../components/Roles/View"));
const OTP = lazy(() => import("../Pages/LoginPage/Otp"));
const ResetPassword = lazy(() => import("../Pages/LoginPage/PasswordReset"));
const ChangePassword = lazy(() => import("../Pages/LoginPage/ChangePassword"));
const ResetChangePassword = lazy(() => import("../Pages/LoginPage/ResetchangePassword"));
const ForgotPassword = lazy(() => import("../Pages/LoginPage/ForgotPassword"));

const HandleRoutes = () => (
  <Routes>
    {/* Public Routes */}
    <Route path='/' element={<LoginPage />} />
    <Route path='/Otp' element={<OTP />} />
    <Route path='/ResetPassword' element={<ResetPassword />} />
    <Route path='/ChangePassword' element={<ChangePassword />} />
    <Route path='/ResetChangePassword' element={<ResetChangePassword />} />
    <Route path='/ForgotPassword' element={<ForgotPassword />} />
    {/* <Route path='/*' element={<NotFound />} /> */}
    {/* Private Routes */}
    <Route element={<ProtectedRoutes />}>
      <Route element={<MenuBar />}>
        <Route path={PATHS.Dashboard} element={<Dash />} />
        <Route path={PATHS.userManagement.root} element={<Roles />} />
        <Route path={PATHS.User} element={<Users />} />
        <Route path={PATHS.UsersEdit} element={<UserEdit />} />
        <Route path={PATHS.IdentityRecords} element={<IdentityRecords />} />
        <Route path={PATHS.Kyc} element={<Kyc />} />
        <Route path={PATHS.Transactions} element={<Transactions />} />
        <Route path={PATHS.IdentityRecordsView} element={<IdentityRecordsView />} />
        <Route path={PATHS.Roles} element={<Roles />} />
        <Route path={PATHS.Audit} element={<Audit />} />
        <Route path={PATHS.Auditdashboard} element={<Auditdashboard />} />
        <Route path={PATHS.Management.root} element={<Management />} />
        <Route path={PATHS.ManagementView} element={<ManagementView />} />
        <Route path={PATHS.Management.pending} element={<ManagementPending />} />
        <Route path={PATHS.Management.completed} element={<ManagementCompleted />} />
        <Route path={PATHS.Management.Cancel} element={<Cancel />} />
        <Route path={PATHS.Roleview} element={<RoleView />} />
      </Route>
    </Route>
  </Routes>
);

export default HandleRoutes;
