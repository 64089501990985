import HandleRoutes from "./Routes/HandleRoutes";
import { ToastContainer } from "react-toastify";
import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";



// i18n
//   .use(initReactI18next)
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     fallbackLng: "fr",
//     detection: {
//       order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
//       caches: ["cookie"],
//     },
//     backend: {
//       loadPath: "/{{lng}}/translation.json",
//     },
//     react: {
//       useSuspense: false,
//     },
//   });
const App = () => {
  return (
    <>
      <ToastContainer
        closeOnClick={true}
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />

      <HandleRoutes />
    </>
  );
};

export default App;
